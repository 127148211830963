<template>
  <div class="slideshow-wrapper">
    <div class="slideshow-photos">
      <transition name="fade" v-for="(slide, index) in slides" :key="index">
        <a
          :class="['slideshow-photo']"
          :style="`background-image: url(${slide['img'].replace('www', 'cdn')});`"
          v-if="checkActive(index)"
          :href="slide['url']"
        ></a>
      </transition>
    </div>
    <ul class="slideshow-titles">
      <li
        v-for="(slide, index) in slides"
        :key="index"
        :class="{ active:checkActive(index) }"
        @mouseover="setSlide(index); markHovering(true);"
        @mouseleave="markHovering(false)"
      >
        <a :href="slide['url']">{{ truncatedTitle(index) }}</a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';

$photo-height: 530;
$photo-width: 860;
$photo-relative-width: 0.58;
$photo-relative-width-calc: percentage($photo-relative-width);
$titles-relative-width-calc: percentage(1 - ($photo-relative-width));
$slideshow-photo-ratio-small: percentage(math.div($photo-height, $photo-width));
$slideshow-photo-ratio: percentage(math.div(math.div($photo-height, $photo-width), math.div(1,$photo-relative-width)));
$brand-blue: #002395;
$small: 900px;

.slideshow-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: $small) { flex-wrap: wrap; }
  border-top: 4px solid $brand-blue;

  .slideshow-photos {
    display: block;
    position: relative;
    width: 100%;
    @media screen and (min-width: $small) { width: $photo-relative-width-calc; }
    padding: 0;
    margin: 0;
    padding-bottom: $slideshow-photo-ratio-small;
    @media screen and (min-width: $small) { padding-bottom: $slideshow-photo-ratio; }
    background-color: #9a9a9a;

    .slideshow-photo {
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      background-size: cover;
      background-position: 50% 50%;
    }
  }

  .slideshow-titles {
    width: 100%;
    @media screen and (min-width: $small) { width: $titles-relative-width-calc; }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      flex: 1;
      margin: 0;
      padding: 1rem 0;
      @media screen and (min-width: $small) { padding: 1rem 1.5rem; }
      font-size: 1.2rem;
      @media screen and (min-width: $small) { font-size: 1.3rem; }
      border-bottom: 1px solid #9a9a9a;
      line-height: 1.3;

      a {
        color: #848889;
        text-decoration: none;

        &:hover { color: #002395; }
      }

      &.active a {
        color: $brand-blue;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },

  data() {
    return {
      currentSlide: 0,
      hovering: false,
    }
  },

  mounted: function () {
    // Preload slide images
    const image1 = new Image();
          image1.src = this.slides[0]['img'].replace('www', 'cdn')

    const image2 = new Image();
          image2.src = this.slides[1]['img'].replace('www', 'cdn')

    const image3 = new Image();
          image3.src = this.slides[2]['img'].replace('www', 'cdn')

    const image4 = new Image();
          image4.src = this.slides[3]['img'].replace('www', 'cdn')

    let self = this
    window.setInterval(() => {
      if(!self.hovering) {
        self.goToNextSlide()
      }
    }, this.slideSpeed * 1000)
  },

  methods: {
    goToNextSlide() {
      this.currentSlide = this.currentSlide < (this.slides.length - 1) ? this.currentSlide + 1 : 0;
      // console.log(`Slide: ${this.currentSlide}`)
    },

    markHovering(value) {
      this.hovering = value
    },

    setSlide(index) {
      this.currentSlide = index
    },

    checkActive(index) {
      return this.currentSlide === index
    },

    truncatedTitle(index, length = 70) {
      const fullTitle = this.slides[index]['title']
      return fullTitle.substring(0,length) + ( fullTitle.length > length ? '...' : '');
    }
  },

  computed: {
    slides: function() {
      return JSON.parse(this.slidesJson)
    }
  },

  props: {
    slideSpeed: {
      type: Number,
      default: 4,
    },
    slidesJson: {
      type: String,
      default: '',
    }
  }
}
</script>
