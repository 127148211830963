import { createApp } from 'vue'
import App from './App.vue'

const mountID = '#jhs-slideshow-app'
const mountEl = document.querySelector(mountID);

// console.log(mountEl)
// console.log(`Mount Element: ${mountID}`)
// console.log(mountEl.dataset)
// console.log(`Slides Passed from PHP: ${mountEl.dataset.slides}`)

const app = createApp(App, { ...mountEl.dataset })
	app.mount(mountID)